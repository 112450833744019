import React, { useContext, useState, useEffect } from "react";
import IconVrf from "../../../../../../assets/vrf.png";
import { AuthContext } from "../../../../../../context/AuthProvider";
import {
  VRFWrapper,
  Header,
  IconWrapper,
  Icon,
  TittlesWrapper,
  Button,
  WrapperUnits,
  UnitCard,
} from "./VRFConfigurations.style";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getVRF, updateVRF } from "./helper";
import Tooltip from "@mui/material/Tooltip";
import { GET_ALL_ROOMS_BY_PROJECT } from "./controllerRooms";
import { useQuery } from "@apollo/client";
function VRFConfigurations() {
  const { data, loading, error, refetch } = useQuery(GET_ALL_ROOMS_BY_PROJECT, {
    variables: { projectId: 127 },
    fetchPolicy: "cache-and-network",
  });
  const { tokenvrf, setTokenvrf } = useContext(AuthContext);
  const [id, setId] = React.useState("");
  const [areas, setAreas] = React.useState([]);
  const [unit, setUnit] = React.useState([]);
  const [initialUnit, setInitialUnit] = React.useState([]);
  const [dataQuery, setDataQuery] = React.useState([]);
  const getUnit = async () => {
    try {
      const response = await getVRF();
      if (response) {
        setUnit(
          response?.body.map((unit) => ({
            name: unit.name,
            unitId: unit.unitId,
            roomId: unit.roomId,
          }))
        );
        setInitialUnit(
          response?.body.map((unit) => ({
            name: unit.name,
            unitId: unit.unitId,
            roomId: unit.roomId,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const updateUnit = async (data) => {
    try {
      const response = await updateVRF(data);
      if (response) {
        getUnit()
        console.log("update responde", response);
      }
    } catch (error) {
      console.log("error update", error);
    } finally {
    }
  };
  const getRoomIdByUnitId = (unitId) => {
    const foundUnit = unit.find((unitItem) => unitItem.unitId === unitId);
    return foundUnit ? foundUnit.roomId : null;
  };

  useEffect(() => {
    getUnit();
  }, []);
  React.useEffect(() => {
    setAreas(
      data?.getAllRoomsByProject.map((areas) => ({
        name: areas.name,
        id: areas.id,
      }))
    );
    setDataQuery(data?.getAllRoomsByProject);
  }, [data]);

  function logout() {
    localStorage.removeItem("tokenvrf");
    localStorage.removeItem("projectIdVRF");
    setTokenvrf();
  }
  const updateRoomIdByUnitId = (unitId, newRoomId) => {
    setUnit((prevUnits) =>
      prevUnits.map((unitItem) =>
        unitItem.unitId === unitId
          ? { ...unitItem, roomId: newRoomId }
          : unitItem
      )
    );
  };
  const UpdateUnit = (unitId) => {
    // encuentra la unidad en el array 'unit'
    const foundUnit = unit.find((unitItem) => unitItem.unitId === unitId);

    if (foundUnit) {
      // Encuentra el objeto en dataQuery que tenga el mismo id que el roomId de foundUnit
      const matchedRoom = dataQuery?.find(
        (room) => room.id === foundUnit.roomId
      );

      // Agrega el typeGateway, si no se encuentra, agrega una cadena vacía
      foundUnit.typeGateway = matchedRoom?.Controller?.typeGateway || "";
      foundUnit.projectId = parseInt(localStorage.getItem("projectIdVRF"));

      // Aquí puedes hacer lo que necesites con foundUnit, como enviarlo al servidor
      console.log("Unidad actualizada:", foundUnit);

      updateUnit([foundUnit])
    }
  };

  const hasUnitChanged = (unitId) => {
    const currentUnit = unit.find((unitItem) => unitItem.unitId === unitId);
    const initialUnitItem = initialUnit.find(
      (unitItem) => unitItem.unitId === unitId
    );

    if (!currentUnit || !initialUnitItem) {
      return false; // Si no se encuentra el unitId, consideramos que no hubo cambios
    }

    return currentUnit.roomId !== initialUnitItem.roomId;
  };

  return (
    <VRFWrapper>
      <Header>
        <IconWrapper>
          <Icon src={IconVrf} />
        </IconWrapper>
        <TittlesWrapper>
          <h1>Flujo de Refrigerante Variable VRF</h1>
          <p>
            Podrás editar información de las unidades, vincular
            <br />a una área en específico del proyecto seleccionado.
          </p>
        </TittlesWrapper>
        <Button onClick={logout}>Salir</Button>
      </Header>
      <WrapperUnits>
        {unit?.map((element, key) => (
          <UnitCard>
            <h3 className="name-unit">{element.name}</h3>
            <div className="unit-id-content">
              <p>
                <strong>Id de la unidad:</strong>{" "}
              </p>
              <p>{element.unitId}</p>
            </div>
            <div className="wrapper-area">
              <div>
                <p>
                  <strong>Asociada al area:</strong>
                </p>
              </div>

              <FormControl sx={{ width: "200px" }} size="small">
                <InputLabel
                  id="demo-select-small-label"
                  InputProps={{ sx: { borderRadius: 2 } }}
                >
                  Proyectos
                </InputLabel>
                <Select
                  sx={{ borderRadius: 2 }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={getRoomIdByUnitId(element?.unitId)}
                  label="Proyectos"
                  onChange={(event) =>
                    updateRoomIdByUnitId(element.unitId, event.target.value)
                  }
                >
                  {areas?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Tooltip
              title={
                !hasUnitChanged(element.unitId)
                  ? "La unidad no presenta cambios"
                  : ""
              }
              placement="bottom"
            >
              <button
                className="save-button"
                disabled={!hasUnitChanged(element.unitId)}
                onClick={() => UpdateUnit(element.unitId)}
              >
                Guardar cambios
              </button>
            </Tooltip>
          </UnitCard>
        ))}
      </WrapperUnits>
    </VRFWrapper>
  );
}

export default VRFConfigurations;
