//Material UI components
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";

export const handleBatteryLevelColor = (BatteryLevel) => {
  if (!BatteryLevel) {
    return "__icon_battery_sensor_unknown";
  } else if (BatteryLevel <= 100 && BatteryLevel >= 51) {
    return "__icon_battery_sensor";
  } else if (BatteryLevel <= 50 && BatteryLevel >= 11) {
    return "__icon_battery_half_level";
  } else if (BatteryLevel <= 10 && BatteryLevel >= 1) {
    return "__icon_battery_sensor_alert";
  }
};

export const handleBatteryLevel = (BatteryLevel) => {
  if (!BatteryLevel) {
    return <BatteryUnknownIcon />;
  } else if (BatteryLevel > 90) {
    return <BatteryFullIcon />;
  } else if (BatteryLevel > 80) {
    return <Battery90Icon />;
  } else if (BatteryLevel > 70) {
    return <Battery80Icon />;
  } else if (BatteryLevel > 50) {
    return <Battery60Icon />;
  } else if (BatteryLevel > 40) {
    return <Battery50Icon />;
  } else if (BatteryLevel > 20) {
    return <Battery30Icon />;
  } else if (BatteryLevel > 10) {
    return <Battery20Icon />;
  } else {
    return <BatteryAlertIcon />;
  }
};
