import { useQuery } from "@apollo/client";
import {
  GET_METTERS_PROJECTS_LOGGED_USER,
  GET_PROJECTS_BY_USERS,
} from "../../../controllers/usersControllers";

//Components
import AddDeviceButton from "./components/AddDeviceButton";
import AddNewMetersModal from "./components/AddNewMetersModal";
import ManagementButton from "./components/ManagementButton";
import NoDeviceInProject from "./components/NoDeviceInProject";
import NoSelectedDevice from "./components/NoSelectedDevice";
import Status from "../../../components/tableInformation/components/Status";
import TableInfo from "../../../components/tableInformation/TableInfo";
import RenderVRF from "./components/vrfManagement/indexVRF"


export const RenderInformationCases = (props) => {
  const { selectedDevice } = props;
  const { data, loading, error, refetch } = useHandleQuery(selectedDevice);
  const dataProjects = data?.getAllProjectsByUser?.Projects;
  const dataTable = data?.getMettersProjectsUsers?.data;
  const newMettersExists = data?.getMettersProjectsUsers?.data?.length;

  switch (selectedDevice) {
    case 0:
      if (newMettersExists <= 0 || newMettersExists == undefined) {
        return <NoSelectedDevice />;
      }

    case 1:
      if (newMettersExists <= 0) {
        return <NoDeviceInProject data={data} dataProjects={dataProjects} />;
      } else if (newMettersExists > 0) {
        return (
          <TableInfo
            dataTable={dataTable}
            amountColumns={tableDataBuilder({
              Status,
              AddDeviceButton,
              ManagementButton,
            })}
          />
        );
      }
    case 2:
      return <RenderVRF />
    default:
      return null;
  }
};

export const dataSelector = [
  { id: 1, name: "Macromedidor" },
  { id: 2, name: "Flujo de Refrigerante Variable(VRF)" },
];

export const tableDataBuilder = (dataBuilder) => {
  const { Status, AddDeviceButton, ManagementButton } = dataBuilder;
  return [
    { label: "Nombre", dataKey: "name" },
    {
      label: "Estado del Proyecto",
      dataKey: "enabled",
      component: Status,
    },
    { label: "País", dataKey: "country" },
    { label: "Ciudad", dataKey: "city" },
    { label: "Agregar", component: AddDeviceButton },
    { label: "Gestionar", component: ManagementButton },
  ];
};

export const enabledoRDesibaledAddButton = (data) => {
  if (data.length > 0) {
    return false;
  } else {
    return true;
  }
};

export const useHandleQuery = (device) => {
  const id = parseInt(localStorage.getItem("np"));
  let query = null;
  let skipCondition = false;

  switch (device) {
    case 1:
      query = GET_METTERS_PROJECTS_LOGGED_USER;
      skipCondition = false;
      break;

    default:
      skipCondition = true;
  }

  const { data, loading, error, refetch } = useQuery(
    GET_METTERS_PROJECTS_LOGGED_USER,
    {
      fetchPolicy: "network-only",
      variables: { id },
      skip: skipCondition,
    }
  );

  return { data, loading, error, refetch };
};
