export const handle_start_and_end_time = (range) => {
  const start_time = new Date(range.startDate);
  const end_time = new Date(range.endDate);

  const start_time_result = `${start_time.getFullYear()}/${start_time.getMonth()}/${start_time.getDate()}`;
  const end_time_result = `${end_time.getFullYear()}/${end_time.getMonth()}/${end_time.getDate()}`;

  return {
    start: start_time_result,
    end: end_time_result,
  };
};
