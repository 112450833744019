import styled from "styled-components";
export const VRFWrapper = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding: 1rem;
    border-radius: 20px;
    margin-top: 30px;
    background-color: #f5f3f3;
    justify-content: center;
    margin-bottom: 30px;

`;
export const Header = styled.div`
    margin-top: 20px;
    width: 95%;
    display: flex;

`
export const IconWrapper = styled.div`
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: solid 4px #ffffff;
    background-color: #e6ecef;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Icon = styled.img`
    height: 130px;
    width: 130px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`
export const TittlesWrapper = styled.div`
    align-self: center;
    margin-left: 15px;
    font-family: "Montserrat", sans-serif;
    p{
        color: grey;
    }
`

export const Button = styled.button`
    position: absolute;
    color: red;
    margin-left: 68%;
    height: 30px;
    width: 100px;
    border-radius: 8px;
    background-color: #ffffff;
    font-family: "Montserrat", sans-serif;
    border: none;
    cursor: pointer;
    :hover{
        border: solid 1px red;
    }
    
`
export const WrapperUnits = styled.div`

    display: grid;
    gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    margin-top: 30px;
    justify-items: center; 
    align-items: center; 

`
export const UnitCard = styled.div`
    background-color: #ffffff;
    width: 390px;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
    p{
        font-family: "Montserrat", sans-serif;
    }
    .name-unit{
        font-family: "Montserrat", sans-serif;
    }
    .unit-id-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .wrapper-area{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .save-button{
        height: 30px;
        border-radius: 8px;
        background-color: #f5f3f3;
        font-family: "Montserrat", sans-serif;
        border: none;
        cursor: pointer;
    }
    .save-button:hover{
        border: solid 1px #000000;
    }


`

