import React from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const NoSelectedDevice = () => {
  return (
    <div className="warnings_container">
      <div className="no_selected_filter">
        <WarningAmberRoundedIcon sx={{ height: "88px", width: "98.29px" }} />
        <span>
          No se ha seleccionado ningún elemento del filtro, por favor seleccione
          un tipo de dispositivo.
        </span>
      </div>
    </div>
  );
};

export default NoSelectedDevice;
