export async function getVRF() {
    // const url = "http://10.0.5.101:3301/api/v1/unit/customer/2";
    const url = `https://www.cool-master-vrf-test.horussmartenergyapp.com/api/v1/unit/customer/${localStorage.getItem("projectIdVRF")}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("tokenvrf")}`,
                'Content-Type': 'application/json',
                "apikey": "integrationVrf"
            },
            body: JSON.stringify(),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error al realizar la petición:', error);
        throw error;
    }
}
export async function updateVRF(data) {
    // const url = "http://10.0.5.101:3301/api/v1/unit/customer/2";
    const url = "https://www.cool-master-vrf-test.horussmartenergyapp.com/api/v1/customerCoolMaster";
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("tokenvrf")}`,
                'Content-Type': 'application/json',
                "apikey": "integrationVrf"
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error al realizar la petición:', error);
        throw error;
    }
}