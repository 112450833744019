import { RenderContent } from './helperVRFmanagemnt'


function indexVRF() {
  return (
    <>{RenderContent()}</>
    
  )
}

export default indexVRF