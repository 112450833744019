
export function handleChangeLevel(limite, incrementar, level, setReferenceLevel, intervalRef, setLevel) {
  clearInterval(intervalRef.current);
  intervalRef.current = setInterval(() => {
    setReferenceLevel(prevLevel => {
      const step = 10;
      let newValue = incrementar ? prevLevel + step : prevLevel - step;
      newValue = incrementar ? Math.min(newValue, limite) : Math.max(newValue, 0);

      // Si el nuevo valor alcanza el límite, actualiza directamente con setLevel y limpia el intervalo
      if (newValue === limite || (!incrementar && newValue <= 0)) {
        clearInterval(intervalRef.current);
        setLevel(newValue); // Asegura que el nivel se actualice al valor de newValue cuando se detiene
      }

      return newValue;
    });
  }, 500);
}

export function initialValue(typeGateway, data) {
  if (typeGateway === "horus_700") {
    const found = data.curtains.variables.items.find(element => element.name === "dimmer");
    return parseInt(found.status)
  } else if (typeGateway === "horus_g150") {
    return parseInt(data?.item?.variables?.LoadLevelStatus)
  }
}


export function stopChange(intervalRef) {
  clearInterval(intervalRef.current);
}

export function handleSliderChange(newValue, setLevel, intervalRef, setReferenceLevel) {
  stopChange(intervalRef);
  setLevel(newValue);
  setReferenceLevel(newValue)
}

export const sendWebSocketData = (data, client) => {
  try {
    if (client && client.readyState === WebSocket.OPEN) {
      client.send(JSON.stringify(data));
      return { success: true, message: "Datos enviados exitosamente." };
    } else {
      console.error("El socket no está abierto o no está listo para enviar datos.");
      return { success: false, message: "El socket no está abierto o no está listo para enviar datos." };
    }
  } catch (error) {
    console.error("Error al enviar datos:", error.message);
    return { success: false, message: "Error al enviar datos." };
  }
};






