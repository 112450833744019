import React, {
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { useState, useEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_PHASES_MEASURER_REPORT } from "../../controllers/measurersController";
import { PDF_MEASURE_PHASES } from "../../controllers/pdfControllers";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Highcharts from "highcharts/highstock";
import { AuthContext } from "../../context/AuthProvider.js";
import HighchartsReact from "highcharts-react-official";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Loading from "../loading/Loading";
import {
  sum_of_the_kwh,
  date_of_the_kwh,
  round_digits,
  calc_date_range,
  date_last,
  defice_toolTip_date,
  define_time_zone,
  convert_time_measurergeneral,
  dateRangeButton,
  calculatedMinParameter,
  calculatedMaxParameter,
  roundTotalBy,
  sumTotalBy,
  formatDateLocal,
} from "../../utils/helpers";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateCustom from "../dates/DateCustom";
import html2canvas from "html2canvas";
//newincorporation
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

//multilanguage
import { useTranslation } from "react-i18next";
import { string } from "prop-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MeasurerPhases = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [pdfGenerator] = useMutation(PDF_MEASURE_PHASES);
  const [basePdf, setBasePdf] = React.useState();
  const {
    dateReport,
    setOpenDateCustom,
    setDateReport,
    rangeTable,
    graphic,
    setGraphic,
    setRangeTable,
    setLoadingPdf,
    pie,
    setPie,
  } = useContext(AuthContext);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { measurer, timeZone } = props;
  const [date_range, setDateRange] = useState([]);
  const [dayActual, setDayActual] = useState(new Date().getTime());
  const [parameterMax, setParameterMax] = useState(
    calculatedMaxParameter(dateReport, timeZone)
  );
  const [parameterMin, setParameterMin] = useState(
    calculatedMinParameter(dateReport, timeZone)
  );
  const [dateMax, setDateMax] = useState();
  const [dateMin, setDateMin] = useState();
  const printRef = React.useRef();
  const printRefP = React.useRef();

  /* const [getPhasesMeasure, { data, loading, error }] = useLazyQuery(
    GET_PHASES_MEASURER_REPORT
  ); */

  const { data, loading } = useQuery(GET_PHASES_MEASURER_REPORT, {
    variables: {
      ProjectId: parseInt(localStorage.getItem("id"), 10),
      date_range: date_range,
      id_parent: parseFloat(measurer.zwaveId),
      start_time: parameterMin,
      end_time: parameterMax,
      time_zone: String(timeZone),
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let day_time_zone =
      dateReport[0]?.startDate?.toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      dateReport[0]?.endDate?.toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      dateReport[0]?.startDate?.toString().substring(0, 16) + "00:00:00 GMT";
    let param_end =
      dateReport[0]?.endDate?.toString().substring(0, 16) + "23:59:59 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();
    /* getPhasesMeasure({
      variables: {
        ProjectId: parseInt(localStorage.getItem("id"), 10),
        date_range: date_last(calc_date_range(dateReport)),
        id_parent: parseFloat(measurer.zwaveId),
        start_time: calculatedMinParameter(dateReport, timeZone),
        end_time: calculatedMaxParameter(dateReport, timeZone),
      },
      fetchPolicy: "no-cache",
    }) */ //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //range
    setDateRange(date_last(calc_date_range(dateReport)));
    setRangeTable(dateReport);
  }, []);

  const handleQueryReport = () => {
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let day_time_zone =
      dateReport[0]?.startDate?.toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      dateReport[0]?.endDate?.toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;

    let param =
      dateReport[0]?.startDate?.toString().substring(0, 16) + "00:00:00 GMT";
    let param_end =
      dateReport[0]?.endDate?.toString().substring(0, 16) + "23:59:59 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //range
    setDateRange(date_last(calc_date_range(dateReport)));
    //
    setRangeTable(dateReport);
    setDayActual(dateReport[0]?.startDate?.getTime());
  };

  const back = () => {
    const backday = dayActual - 86400000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    const new_backday = new Date(backday);
    let day_time_zone =
      new Date(new_backday).toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      new Date(new_backday).toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      new Date(new_backday).toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    setDateReport([
      {
        startDate: new_backday,
        endDate: new_backday,
        key: "selection",
      },
    ]);

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    //
    setRangeTable([
      {
        startDate: new_backday,
        endDate: new_backday,
        key: "selection",
      },
    ]);
    setDateRange(dateRangeButton(new_backday));
    setDayActual(backday);
  };

  const next = () => {
    const nextday = dayActual + 86400000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    const new_nextday = new Date(nextday);
    let day_time_zone =
      new Date(new_nextday).toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      new Date(new_nextday).toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      new Date(new_nextday).toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    setDateReport([
      {
        startDate: new_nextday,
        endDate: new_nextday,
        key: "selection",
      },
    ]);

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setRangeTable([
      {
        startDate: new_nextday,
        endDate: new_nextday,
        key: "selection",
      },
    ]);
    setDateRange(dateRangeButton(new_nextday));
    setDayActual(nextday);
  };

  useEffect(() => {
    if (graphic) {
      if (date_range.length < 1 || date_range.length == 1) {
        imagePie();
      }
      if (date_range.length > 1) {
        dataPdf();
      }
    }
  }, [graphic]);

  useEffect(() => {
    if (pie) {
      dataPdf();
    }
  }, [pie]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Reporte por fases.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    /* let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(basePdf) +
        "'></iframe>"
    ); */
    setLoadingPdf(false);
    setGraphic("");
    setPie("");
    setBasePdf("");
  };

  const imageGraphic = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    setGraphic(data.slice(22));
    //var cadena = data.slice(22);
    /* if (typeof link.download === "string") {
      link.href = data;
      link.download = "imgGraph.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }  */
    /* imagePie() */
  };

  const imagePie = async () => {
    const elementTwo = printRefP.current;
    const canvas = await html2canvas(elementTwo);
    const data = canvas.toDataURL("image/jpg");
    //const link = document.createElement("a");
    await setPie(data.slice(22));
    /* else {

    /* if (typeof link.download === "string") {
      link.href = data;
      setPie(data);
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } */
  };

  const seeData = () => {
    setLoadingPdf(false);
    setGraphic("");
    setPie("");
  };

  useImperativeHandle(ref, () => ({
    async measurersPhases() {
      setLoadingPdf(true);
      await imageGraphic();
      //seeData();
      //setLoadingPdf(true);
    },
  }));

  function dataPdf() {
    try {
      pdfGenerator({
        variables: {
          image: [graphic, pie],
          data: data.getPhasesMeasureReport,
          date_range: date_range,
          startTime: String(parameterMin),
          finishTime: String(parameterMax),
          nameProject: localStorage.getItem("nameProject"),
          userName: localStorage.getItem("name"),
          imageProject: localStorage.getItem("imgProject"),
          datePrint: localStorage.getItem("localTime"),
        },
      })
        .then((resul) => {
          setBasePdf(resul.data.pdfMeasurePhases);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {loading || !data ? (
        <Loading />
      ) : (
        <div className="_measurergeneral_container_dates_">
          <DateCustom
            handleQueryReport={handleQueryReport}
            back={back}
            next={next}
          />
          {
            <>
              {data?.getPhasesMeasureReport &&
              !(data?.getPhasesMeasureReport?.data_kwh?.length === 0) ? (
                <>
                  {dateMax && (
                    <div ref={printRef}>
                      <div className="_measurergeneral_container_graphics_">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: "column",
                            },
                            title: {
                              text: t(
                                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_KWT_CONSUMPTION_GRAPH.TITLE_DESCRIPTION"
                              ),
                            },
                            xAxis:
                              date_range.length <= 1
                                ? {
                                    type: "datetime",
                                    min: parameterMin,
                                    max: parameterMax - 3600000,
                                    tickInterval: 3600000,
                                    labels: {
                                      format: "{value:%H:%M}",
                                    },
                                  }
                                : {
                                    type: "datetime",
                                  },
                            yAxis: {
                              min: 0,
                              max: data?.getPhasesMeasureReport?.length,
                              tickAmount:
                                data?.getPhasesMeasureReport.length + 1,
                              title: {
                                text: t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_KWT_CONSUMPTION_GRAPH.Y_AXIS_DESCRIPTION"
                                ),
                              },
                              stackLabels: {
                                enabled: true,
                                formatter: function () {
                                  return this.total.toFixed(1);
                                },
                              },
                            },
                            colors: [
                              "#04b431",
                              "#013adf",
                              "#df01a5",
                              "#DF2653",
                              "#615C5D",
                              "#D5DF03",
                              "#1C1C1C",
                              "#ee0f0f",
                              "#FFAF0E",
                              "#1f89e5e6",
                              "#1f89e566",
                            ],
                            plotOptions: {
                              column: {
                                stacking: "stream",
                                maxPointWidth: 50,
                              },
                            },
                            time: {
                              timezoneOffset: define_time_zone(
                                date_range,
                                timeZone
                              ),
                            },
                            tooltip: {
                              xDateFormat: defice_toolTip_date(date_range),
                              valueDecimals: 1,
                              headerFormat: "<b>{point.key}</b><br>",
                              valueSuffix: " kW h",
                            },
                            series: data?.getPhasesMeasureReport?.data_kwh,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="date_vacio_report_measurer">
                    <span>
                      <i class="fas fa-exclamation-triangle"></i>{" "}
                      {t(
                        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_KWT_CONSUMPTION_GRAPH.EMPTY_DATA"
                      )}
                    </span>
                  </div>
                </>
              )}
              <>
                <div ref={printRefP}>
                  <div className="_measurergeneral_container_graphics_">
                    {data?.getPhasesMeasureReport &&
                    !(date_range.length > 1) ? (
                      <Box
                        sx={{
                          width: "95%",
                          height: "30%",
                          justifyContent: "center",
                          alignItems: "center",
                          typography: "body1",
                        }}
                      >
                        <TabContext value={value}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              marginLeft: "30px",
                            }}
                          >
                            <TabList
                              onChange={handleChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                label={t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_WATTS_GRAPH.TOTAL_TAB"
                                )}
                                value="1"
                              />
                              {/*  <Tab
                                label={t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_WATTS_GRAPH.WATTS BY TWEEZERS"
                                )}
                                value="2"
                              /> */}
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={{
                                chart: {
                                  zoomType: "x",
                                  type: "spline",
                                },
                                title: {
                                  text: "Watts",
                                },
                                xAxis: {
                                  type: "datetime",
                                  min: dateMin,
                                  max: dateMax,
                                  tickInterval: 3600000,
                                  labels: {
                                    format: "{value:%H:%M}",
                                  },
                                },
                                yAxis: {
                                  min: 0,
                                  title: {
                                    text: t(
                                      "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_WATTS_GRAPH.Y_AXIS_DESCRIPTION"
                                    ),
                                  },
                                },
                                colors: ["#000000"],
                                tooltip: {
                                  xDateFormat: "Hora: %H:%M:%S",
                                  valueSuffix: " watts",
                                  valueDecimals: 1,
                                  shared: true,
                                },
                                plotOptions: {
                                  column: {
                                    stacking: "line",
                                  },
                                  spline: {
                                    lineWidth: 1.5,
                                    states: {
                                      hover: {
                                        lineWidth: 2.5,
                                      },
                                    },
                                    marker: {
                                      enabled: false,
                                    },
                                  },
                                  series: {
                                    turboThreshold: 0,
                                    marker: {
                                      enabled: false,
                                      radius: 0.1,
                                      symbol: "circle",
                                    },
                                  },
                                },
                                time: {
                                  timezoneOffset: define_time_zone(
                                    date_range,
                                    timeZone
                                  ),
                                },
                                series:
                                  data?.getPhasesMeasureReport?.data_watts,
                              }}
                            />
                          </TabPanel>
                          {/* <TabPanel value="2">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={{
                                chart: {
                                  zoomType: "y",
                                  type: "spline",
                                },
                                title: {
                                  text: "Watts",
                                },
                                xAxis: {
                                  type: "datetime",
                                  min: dateMin,
                                  max: dateMax,
                                  tickInterval: 3600000,
                                  labels: {
                                    format: "{value:%H:%M}",
                                  },
                                },
                                yAxis: {
                                  min: 0,
                                  title: {
                                    text: "Vatios(W)",
                                  },
                                },
                                colors: ["#f7ce68", "#468BE0", "#ff2525"],
                                tooltip: {
                                  xDateFormat: "Hora: %H:%M:%S",
                                  valueSuffix: " watts",
                                  valueDecimals: 1,
                                  shared: true,
                                },
                                plotOptions: {
                                  column: {
                                    stacking: "line",
                                  },
                                  spline: {
                                    lineWidth: 1.5,
                                    states: {
                                      hover: {
                                        lineWidth: 2.5,
                                      },
                                    },
                                    marker: {
                                      enabled: false,
                                    },
                                  },
                                  series: {
                                    turboThreshold: 0,
                                    marker: {
                                      enabled: false,
                                      radius: 0.1,
                                      symbol: "circle",
                                    },
                                  },
                                },
                                time: {
                                  timezoneOffset: define_time_zone(
                                    date_range,
                                    timeZone
                                  ),
                                },
                                series:
                                  data?.getPhasesMeasureReport
                                    ?.watts_per_clamps,
                              }}
                            />
                          </TabPanel> */}
                        </TabContext>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="_measurergeneral_container_tables_">
                  <TableContainer component={Paper}>
                    <div className="__report_measurer_table_total_title__">
                      {t(
                        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.TITLE"
                      )}
                      ({convert_time_measurergeneral(rangeTable)})
                    </div>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.MEASURER_NUMBER"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.AREAS"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.INITIAL_MEASURE"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.FINAL_MEASURE"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.TOTAL"
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.getPhasesMeasureReport?.totals
                          ? data?.getPhasesMeasureReport?.totals.map(
                              (i, key) => (
                                <StyledTableRow>
                                  <StyledTableCell align="center">
                                    {key + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {i?.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {i?.accumulated
                                      ? `${i?.accumulated?.initial?.toFixed(
                                          1
                                        )} kW h`
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {i.accumulated
                                      ? `${i?.accumulated?.final?.toFixed(
                                          1
                                        )} kW h`
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {round_digits(i?.value)} kW h
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )
                          : false}
                        <StyledTableRow>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                            align="center"
                          >
                            Total
                          </StyledTableCell2>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                          ></StyledTableCell2>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                          ></StyledTableCell2>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                          ></StyledTableCell2>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {round_digits(
                              data?.getPhasesMeasureReport?.total_range
                            )}{" "}
                            kW h
                          </StyledTableCell2>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="__measurergeneral_grid_title_tables__">
                  <span>
                    <hr></hr>
                  </span>
                  <span>
                    {t(
                      "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.TITLE"
                    )}
                  </span>
                  <span>
                    <hr />
                  </span>
                </div>

                <div className="_measurergeneral_grid_tables_">
                  {data.getPhasesMeasureReport.data_kwh ? (
                    data.getPhasesMeasureReport.data_kwh.map((j) => (
                      <div className="__report_measurer_rooms__">
                        {/*  <TableContainer component={Paper}>
                          <div className="__report_measurer_by_room__">
                            {j.name}
                          </div>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">
                                  Hora
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Total
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {j.data.map((i, key) => (
                                <StyledTableRow>
                                  <StyledTableCell align="center">
                                    {date_of_the_kwh(i.x, date_range, key)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {i.y.toFixed(1)} kW h
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                              <StyledTableRow>
                                <StyledTableCell2
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  Total
                                </StyledTableCell2>
                                <StyledTableCell2
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {round_digits(sum_of_the_kwh(j.data))} kW h
                                </StyledTableCell2>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  {data.getPhasesMeasureReport.total_by ? (
                    <div className="__report_measurer_rooms__">
                      <TableContainer component={Paper}>
                        <div className="__report_measurer_by_room__">
                          {t(
                            "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.DESCRIPTION"
                          )}
                        </div>
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.HOUR"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.INITIAL_MEASURE"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.FINAL_MEASURE"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.TOTAL"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t(
                                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.ACCUMULATED"
                                )}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.getPhasesMeasureReport.total_by.map((i) => (
                              <StyledTableRow>
                                <StyledTableCell align="center">
                                  {roundTotalBy(i?.x)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {i?.initialValue === undefined ||
                                  i?.initialValue === null
                                    ? "-----"
                                    : `${i?.initialValue} kwh `}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {i?.finalValue === undefined ||
                                  i?.finalValue === null
                                    ? "-----"
                                    : `${i?.finalValue} kwh `}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {i?.y === null || i?.y === undefined
                                    ? "-----"
                                    : `${i?.y} kwh `}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {i?.dailyAccumulated === null ||
                                  i?.dailyAccumulated === undefined
                                    ? "-----"
                                    : `${i?.dailyAccumulated} kwh `}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                            {/*  <StyledTableRow>
                              <StyledTableCell2
                                component="th"
                                scope="row"
                                align="center"
                              >
                                Total
                              </StyledTableCell2>
                              <StyledTableCell2
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {sumTotalBy(
                                  data.getPhasesMeasureReport.total_by
                                )}{" "}
                                kW h
                              </StyledTableCell2>
                            </StyledTableRow> */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            </>
          }
        </div>
      )}
    </div>
  );
});

export default MeasurerPhases;
