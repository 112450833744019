// new devices
import Swicth from "./components/Swicth/Swicth";
import NewDoorSensor from "./components/sensors/NewDoorSensor";
import NewMotionSensor from "./components/sensors/NewMotionSensor";
import CurtainSwitch from "../../../components/devices/CurtainSwitch/CurtainSwitch";
import { EventEmitter } from "events";

const {
  REACT_APP_SOCKET_PRODUCTION_NEWCONTROLLER,
  REACT_APP_SOCKET_STAGING_NEWCONTROLLER,
} = process.env;

export const renderNewDevices = (devices, obj) => {
  const permission =
    obj.permissionsRoleUser?.permissionsRole?.AreaDispVer &&
    obj.permissionsRoleUser?.permissionsRole?.AreaDisp;

  return devices.map((elements) => (
    <div className="_container_new_devices_all">
      {elements?.map((secondElement, key) => {
        if (secondElement.Category.category === "switch" && permission) {
          return (
            <Swicth
              allSwitches={secondElement}
              controllerStatus={obj.controllerStatus}
              dataSocket={obj.dataSocket}
              key={key}
              serial={obj.serial}
              sendMessage={obj.sendMessage}
            />
          );
        } else if (
          secondElement.Category.category === "security_sensor" &&
          secondElement.Category.sub_category === "door" &&
          permission
        ) {
          return (
            <NewDoorSensor
              controllerStatus={obj.controllerStatus}
              dataSocket={obj.dataSocket}
              sensor={secondElement}
              serial={obj.serial}
              sendMessage={obj.sendMessage}
            />
          );
        } else if (
          secondElement.Category.category === "security_sensor" &&
          secondElement.Category.sub_category === "motion" &&
          permission
        ) {
          return (
            <NewMotionSensor
              controllerStatus={obj.controllerStatus}
              dataSocket={obj.dataSocket}
              sensor={secondElement}
              serial={obj.serial}
              sendMessage={obj.sendMessage}
            />

          );
        } else if (
          secondElement.Category.category === "window_cov" &&
          secondElement.Category.sub_category === "window_cov" &&
          permission
        ) {
          return (
            <CurtainSwitch
              controllerStatus={obj.controllerStatus}
              curtains={secondElement}
              serial={obj.serial}
              endPoint={REACT_APP_SOCKET_PRODUCTION_NEWCONTROLLER}
            />
          );
        } else {
          return null;

        }
      })}
    </div>
  ));
};

export const spliceDevicesData = (devices, count) => {
  let chunkedArray = [];
  let chunkSize = count;

  for (let i = 0; i < devices?.length; i += chunkSize) {
    chunkedArray.push(devices.slice(i, i + chunkSize));
  }

  return chunkedArray;
};

export const filterNameDevices = (devices) => {
  return devices.filter(
    (element) =>
      element?.Category?.category != "600" &&
      (element?.Category?.category != "5" ||
        element?.Category?.sub_category != "1")
  );
};

let socket;
const eventEmitter = new EventEmitter();
export const IsThereVRF = (data) => {
  if (data) {
    // Recorremos cada objeto en el array de datos
    for (const item of data) {
      // Verificamos si la propiedad "variables" existe y si contiene la propiedad "type"
      if (item.variables && item.variables.type === "thermostatVRF") {
        // Si encontramos un objeto que cumple la condición, devolvemos true
        return true;
      }
    }
    // Si no encontramos ningún objeto que cumpla la condición, devolvemos false
    return false;
  }
};

export const SocketVRF = () => {
  if (
    !socket ||
    socket.readyState === WebSocket.CLOSED ||
    socket.readyState === WebSocket.CLOSING
  ) {
    socket = new WebSocket(
      "wss://www.cool-master-vrf-test.horussmartenergyapp.com/ws?apiKey=integrationVrf"
    );

    socket.onopen = async () => {
      //console.log('open');
    };

    socket.onmessage = async ({ data }) => {
      //console.log('Message');
      const message = JSON.parse(data);
      if (message.Name === "UPDATE_UNIT") {
        // Emitir un evento con el identificador del dispositivo y los datos del mensaje
        eventEmitter.emit("updateUnit", message.unitId, message);
      }
    };

    socket.onclose = async (data) => {
      // console.log(data);
      socket = null;
    };

    socket.onerror = async () => console.error;
  }
};

export const getSocket = () => socket;
export const getEventEmitter = () => eventEmitter;
