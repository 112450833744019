import styled from "styled-components";
export const LoginWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const FisrtCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 930px;
    background-color: #f5f3f3;
    border-radius: 18px;
`
export const TittleLogin = styled.h2`
margin-bottom: 0;
`
export const Description = styled.p`
    text-align: center;
`
export const WrapperForm = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    gap:20px;
`
