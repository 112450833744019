import gql from "graphql-tag";

export const GET_ALL_ROOMS_BY_PROJECT = gql`
 query Query($projectId: Int!) {

getAllRoomsByProject(project_id: $projectId) {
  name
  id
  Controller {
      typeGateway
      id
    }
}
}
`;

/*  {
      id
      name
      floor
      status
      alias
      NewRoomId
      visible
      Device {
        variables
        visible
        name
        id
        alias
        Category {
          id
          name
          category
          sub_category
        }
      }
      Controller {
        id
        serial
        online
        typeGateway
      }
    }  */