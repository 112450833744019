import React, { useEffect, useContext } from "react";

//Global Context
import { AuthContext } from "../../context/AuthProvider";

//Material UI components and styles
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";

//Swal modal component
import Swal from "sweetalert2";

//Components
import GraphBatteryModal from "./GraphBatteryLevel/GraphBatteryModal";
import Loading_devices from "../loading/Loading_devices";

//Constants
import { Devices } from "../../constants/devices_types";

//Helpers
import { ExtractTypeGateway } from "../../utils/helpers";
import {
  handleBatteryLevelColor,
  handleBatteryLevel,
} from "./utils/helperBattery";

//Images
import alert from "../../assets/alert-icon.png";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});
const SensorMovimiento = ({ item, send, areaName }) => {
  const typeGateway = ExtractTypeGateway();
  const { t } = useTranslation();
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);
  const [state, setState] = React.useState(
    item?.variables?.Tripped == "1" ? true : false
  );
  const [BatteryLevel, setBatteryLevel] = React.useState(
    item?.variables?.BatteryLevel ? parseInt(item?.variables?.BatteryLevel) : ""
  );
  const [armed, setArmed] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseBattery = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  useEffect(() => {
    setState(item?.variables?.Tripped == "1" ? true : false);
    if (item?.variables?.Armed) {
      setArmed(item?.variables?.Armed == "1" ? true : false);
    }
    if (item?.variables?.BatteryLevel) {
      setBatteryLevel(
        item?.variables?.BatteryLevel
          ? parseInt(item?.variables?.BatteryLevel)
          : ""
      );
    }
  }, [item]);

  const handleChange = async (event) => {
    // await Swal.fire({
    //   title: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TITLE'),
    //   text: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TEXT'),
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.CONFIRMBUTTONTEXT'),
    // }).then((result) => {
    // if (result.isConfirmed) {
    setLoad(true);
    call();
    setArmed(!armed);
    //   }
    //  });
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        send(item, armed);
      }, 2000);
    });
  }

/*   console.log("BatteryLevel:", item?.variables?.BatteryLevel);
 */
  return (
    <div className="_generic_container">
      <div
        className={`_generics_container_title_status ${
          item?.variables?.CommFailure == "1" ? "__status_comfailure__" : null
        }`}
      >
        <div className="_generic__title">
          <span>{item?.alias}</span>
        </div>
        <div
          className={`${
            item?.variables?.CommFailure == "1"
              ? "_generic__status__alert"
              : "__generic_sensor_wifi"
          }`}
        >
          {item?.variables?.CommFailure == "1" ? (
            <>
              <div className="battery_status" onClick={handleCloseBattery}>
                {BatteryLevel && (
                  <span className="battery_percentage">{BatteryLevel}%</span>
                )}
                <div className="battery_icon_container">
                  <span
                    className={handleBatteryLevelColor(BatteryLevel)}
                    onClick={handleCloseBattery}
                  >
                    {handleBatteryLevel(BatteryLevel)}
                  </span>
                </div>

                <div className="__icon_alert_sensor">
                  <img src={alert} alt="alert" width="22" height="22" />
                </div>
              </div>
            </>
          ) : (
            <div className="battery_status" onClick={handleCloseBattery}>
              {BatteryLevel && (
                <span className="battery_percentage">{BatteryLevel} %</span>
              )}

              <div className="battery_icon_container">
                <span className={handleBatteryLevelColor(BatteryLevel)}>
                  {handleBatteryLevel(BatteryLevel)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="_generic_container_clasification">
        <div
          className={`_generic_clasification ${
            load ? "__loading_clasification" : false
          }`}
        >
          <span>
            <img
              src={
                state ? Devices.MOTION_SENSOR_TRIPPED : Devices.MOTION_SENSOR
              }
              alt="clasification"
              width="60"
              height="60"
            />
          </span>
        </div>
        <div className="__loading_devices">
          {load ? <Loading_devices /> : false}
        </div>
        <div
          className={`_generic_toggle row-decoration ${
            load ? "__loading_toogle" : false
          }`}
        >
          <div>
            <ThemeProvider theme={innerTheme}>
              <Switch
                size="medium"
                checked={armed}
                onChange={
                  permissionsRoleUser?.permissionsRole?.AreaDispMan
                    ? handleChange
                    : false
                }
              />
            </ThemeProvider>
            <br></br>
            {!armed ? (
              <span>
                {t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.DISASSEMBLED")}
              </span>
            ) : (
              <span>{t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.ASSEMBLED")}</span>
            )}{" "}
          </div>
        </div>
      </div>
      <GraphBatteryModal
        areaName={areaName}
        open={open}
        name={item?.alias}
        handleClose={handleCloseBattery}
        id={item?.id}
        typeGateway={typeGateway}
      />
    </div>
  );
};

export default SensorMovimiento;
