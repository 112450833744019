import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_METTERS_PROJECTS_LOGGED_USER } from "../../../controllers/usersControllers";
import { AuthContext } from "../../../context/AuthProvider";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//Components
import AutoComplete from "../../../components/tableInformation/components/AutoComplete";
import AddingDeviceProject from "./components/AddingDeviceProject";
import Denied from "../../../components/alert/Denied";
import RenderVRF from "./components/vrfManagement/indexVRF"
import Status from "../../../components/tableInformation/components/Status";
import SelectInfo from "../../../components/tableInformation/components/SelectInfo";

//helper
import { dataSelector, RenderInformationCases } from "./helper";

const softGreen = "#67A46D";

const theme = createTheme({
  palette: {
    success: {
      main: softGreen,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const DevicesManagement = () => {
  const { data, loading, error, refetch } = useQuery(
    GET_METTERS_PROJECTS_LOGGED_USER,
    {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(localStorage.getItem("np")),
      },
    }
  );

  const { selectedDevice, selectedProject, permissionsRoleUser, tokenvrf } =
    useContext(AuthContext);

  const enabled = data?.getMettersProjectsUsers?.data?.map(
    (element) => element.enabled
  );

  const userProject = data?.getAllProjectsByUser?.Projects;

  const allNewMettersExists = data?.getMettersProjectsUsers?.data?.length;

  useEffect(() => {
    if (selectedDevice !== 0) {
      refetch();
    }
  }, [selectedDevice, refetch]);

  <Status enabled={enabled} />;

  return (
    <>
      {permissionsRoleUser?.permissionsRole?.GesDispVer ? (
        <div className="devices_datatable_container">
          <div className="devices_header_container">
            <div className="devices_title_container">
              <h1 className="table_title">Gestión de Dispositivos</h1>
              <span className="title_description">
                Aquí podras gestionar tus dispositivos por proyecto
              </span>
            </div>
            <div className="select_input_container">
              <AddingDeviceProject
                enabled={selectedProject === null ? true : false}
                openModal={false}
              />
              <SelectInfo dataSelect={dataSelector} />
              {allNewMettersExists > 0 && selectedDevice == 1 ? (
                <>
                <AddingDeviceProject enabled={false} openModal={true} />
                <AutoComplete
                  dataFilter={userProject}
                  ensabled={selectedDevice}
                />
                </>
                
              ) : null}
              
            </div>
          </div>

          <div name="renderViewBySelect">
            <RenderInformationCases selectedDevice={selectedDevice} />
          </div>
        </div>
      ) : (
        <Denied />
      )}
    </>
  );
};
export default DevicesManagement;
