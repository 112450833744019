// helpers/api.js
export async function signUpVRF(data) {
    // const url = 'http://10.0.5.101:3301/api/v1/customerCoolMaster/sign-up';
    const url = "https://www.cool-master-vrf-test.horussmartenergyapp.com/api/v1/customerCoolMaster/sign-up"
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
                "apikey": "integrationVrf"
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error al realizar la petición:', error);
        throw error;
    }
}
