import React, { useEffect, useContext, useState } from "react";

//Global Context
import { AuthContext } from "../../../../../context/AuthProvider";

//Material UI components and styles
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";

//Components
import Battery_Newlevel from "../Battery_Newlevel";
import Loading_devices from "../../../../../components/loading/Loading_devices";

//Constants
import { Devices } from "../../../../../constants/devices_types";

//Helper
import {
  handleBatteryLevelColor,
  handleBatteryLevel,
} from "../Temperature/utils/helperBattery";

//Images
import alert from "../../../../../assets/alert-icon.png";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const NewDoorSensor = ({ sensor, serial, dataSocket, controllerStatus }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { alias, variables, Category, name } = sensor;
  const { category, sub_category } = Category;
  const { items, status, type, onLine } = variables;
  const [armed, setArmed] = useState(false);
  const [doorSensor, setDoorSensor] = useState(
    items?.find((e) => e?.name === "security_threat")?.status
  );
  const [battery, setBattery] = useState({
    itemId_Battery: "",
    percentageBattery: "",
  });
  const { permissionsRoleUser } = useContext(AuthContext);

  useEffect(() => {
    if (
      dataSocket?.category === category &&
      sub_category === "door" &&
      dataSocket?.event_name === "security_threat"
    ) {
      const item = items?.find((e) => e.item_id === dataSocket?.itemId);
      if (item) {
        setDoorSensor(dataSocket?.state);
      }
    }

    const batteryVariable = variables?.items.find(
      (item) => item.name === "battery"
    );
    if (batteryVariable) {
      setBattery({
        itemId_Battery: batteryVariable?.item_id,
        percentageBattery: batteryVariable?.status,
      });
    }
  }, [dataSocket]);

  const handleChangesArmed = () => {
    setArmed(!armed);
  };

  const handleCloseBattery = () => {
    setOpen(!open);
  };

  return (
    <>
      {type === "door_sensor" && (
        <div className="_generic_container">
          <div
            className={`_generics_container_title_status ${
              onLine && controllerStatus ? null : "__status_comfailure__"
            }`}
          >
            <div className="_generic__title">
              <span>{alias}</span>
            </div>
            <div
              className={`${
                alias ? "_generic__status__alert" : "__generic_sensor_wifi"
              }`}
            >
              {!alias ? (
                <span className="__icon_alert_sensor">
                  <img src={alert} alt="alert" width="22" height="22" />
                </span>
              ) : (
                <div className="battery_status" onClick={handleCloseBattery}>
                  {battery?.percentageBattery && (
                    <span className="battery_percentage">
                      {battery?.percentageBattery}%
                    </span>
                  )}

                  <div className="battery_icon_container">
                    <span
                      className={handleBatteryLevelColor(
                        battery?.percentageBattery
                      )}
                    >
                      {handleBatteryLevel(battery?.percentageBattery)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="_generic_container_clasification">
            <div
              className={`_generic_clasification ${
                "" ? "__loading_clasification" : ""
              }`}
            >
              <span>
                <img
                  src={
                    doorSensor
                      ? Devices.DOOR_WINDOW_TRIPPED
                      : Devices.DOOR_WINDOW
                  }
                  alt="clasification"
                  width="60"
                  height="60"
                />
              </span>
            </div>
            <div className="__loading_devices">
              {!alias ? <Loading_devices /> : false}
            </div>
            <div
              className={`_generic_toggle row-decoration ${
                !alias ? "__loading_toogle" : false
              }`}
            >
              <div>
                <ThemeProvider theme={innerTheme}>
                  <Switch
                    size="medium"
                    checked={armed}
                    onChange={
                      permissionsRoleUser?.permissionsRole?.AreaDispMan
                        ? handleChangesArmed
                        : false
                    }
                  />
                </ThemeProvider>
                <br></br>
                {doorSensor ? (
                  <span>
                    {t("VIEW_AREA.COMPONENT_DOOR_SENSOR.DISASSEMBLED")}
                  </span>
                ) : (
                  <span>{t("VIEW_AREA.COMPONENT_DOOR_SENSOR.ASSEMBLED")}</span>
                )}
              </div>
            </div>
          </div>
          <Battery_Newlevel
            id_Battery={battery?.itemId_Battery}
            handleCloseBattery={handleCloseBattery}
            name={alias}
            openBattery={open}
          />
        </div>
      )}
    </>
  );
};
export default NewDoorSensor;
