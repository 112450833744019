import React, { useState, useContext } from "react";
import {
  LoginWrapper,
  FisrtCardWrapper,
  TittleLogin,
  Description,
  WrapperForm,
} from "./loginVrf.style";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@mui/material/TextField";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_BY_USER } from "./controllerLogin";
import { signUpVRF } from "./helper";
import { AuthContext } from "../../../../../../context/AuthProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LoginVrf() {
  const { tokenvrf, setTokenvrf } = useContext(AuthContext);
  const [id, setId] = React.useState("");
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [projects, setProjects] = React.useState([]);
  const [loadingvrf, setLoadingvrf] = useState(false);
  const [errorvrf, setErrorvrf] = useState(null);
  const [result, setResult] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState();
  const [severityAlert, setSeverityAlert] = useState()
  const { data, loading, error, refetch } = useQuery(GET_PROJECT_BY_USER, {
    fetchPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    setProjects(data?.getAllProjectsByUser?.Projects);
  }, [data]);

  const handleChange = (event) => {
    setId(event.target.value);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSignUpVRF = async () => {
    setLoadingvrf(true);
    setErrorvrf(null);
    setResult(null);

    const data = {
      email: email,
      password: password,
      projectId: id,
    };

    try {
      const response = await signUpVRF(data);
      setResult(response);
      localStorage.setItem("tokenvrf", response.token);
      localStorage.setItem("projectIdVRF", id);
      setTokenvrf(response.token);
    } catch (error) {
      setOpen(true)
      setSeverityAlert("error")
      setErrorvrf("Error al realizar la petición");
      setMessage("Datos incorrectos")
      console.log(error);
    } finally {
      setLoadingvrf(false);
    }
  };

  return (
    <LoginWrapper>
      <Snackbar open={open} autoHideDuration={4500} onClose={handleClose}>
        <Alert severity={severityAlert}>{message}</Alert>
      </Snackbar>
      <FisrtCardWrapper>
        <TittleLogin>Iniciar Sesion</TittleLogin>
        <Description>
          ingresa con tus datos <br></br>de la aplicacion IOC Controller
        </Description>
        <WrapperForm>
          <TextField
            onChange={(event) => setEmail(event.target.value)}
            InputProps={{ sx: { borderRadius: 2 } }}
            id="outlined-basic"
            label="Correo electronico"
            variant="outlined"
            size="small"
          />
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{ sx: { borderRadius: 2 } }}
            id="outlined-basic"
            size="small"
            label="Contrasena"
            variant="outlined"
            type="password"
          />
          <FormControl sx={{ width: "200px" }} size="small">
            <InputLabel
              id="demo-select-small-label"
              InputProps={{ sx: { borderRadius: 2 } }}
            >
              Proyectos
            </InputLabel>
            <Select
              sx={{ borderRadius: 2 }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={id}
              label="Proyectos"
              onChange={handleChange}
            >
              {projects?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ borderRadius: 3, height: "45px" }}
            onClick={handleSignUpVRF}
          >
            Inciar Sesion
          </Button>
        </WrapperForm>
      </FisrtCardWrapper>
    </LoginWrapper>
  );
}

export default LoginVrf;
