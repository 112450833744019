import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import Loading_devices from "../../loading/Loading_devices";
import alert from "../../../assets/alert-icon.png";
import fanCoolImage from "../../../assets/fant.png";
import { getEventEmitter } from "../../../views/private/devices/helperDevices"; // Importar el EventEmitter

export const VRF = ({ pluggin, gridNumber, item, send }) => {
  const { permissionsRoleUser } = useContext(AuthContext);

  const [CurrentTemperature, setCurrentTemperature] = useState(
    financial(item?.variables?.CurrentTemperature)
  );
  const [BatteryLevel, setBatteryLevel] = useState(
    item?.variables?.BatteryLevel
  );
  const [cool, setCool] = useState(
    item?.variables?.ModeStatus === "CoolOn" ? true : false
  );
  const [heat, setHeat] = useState(
    item?.variables?.ModeStatus === "HeatOn" ? true : false
  );
  const [auto, setAuto] = useState(
    item?.variables?.ModeStatus === "AutoChangeOver" ? true : false
  );
  const [off, setOff] = useState(
    item?.variables?.ModeStatus === "Off" ? true : false
  );
  const [CurrentSetpoint, setCurrentSetpoint] = useState(
    parseInt(financial(item?.variables?.CurrentSetpoint))
  );
  const [currentFanMode, setCurrentFanMode] = useState(
    item?.variables?.CurrentFanMode
  );

  const [load, setLoad] = useState(false);
  const [timer, setTimer] = useState(null); // Estado para el temporizador
  const delay = 1500;

  const minTemperature = 15;
  const maxTemperature = 30;

  function financial(x) {
    return Number.parseFloat(x).toFixed(0);
  }

  // Función para manejar el envío de cambios después de un retraso
  const handleDelayedSend = (newSetPoint, action) => {
    if (timer) {
      clearTimeout(timer); // Limpia el temporizador anterior si existe
    }

    setTimer(
      setTimeout(() => {
        send(item, String(newSetPoint), action);
        setLoad(true);
        timeLoad();
      }, delay)
    );
  };

  const downSet = () => {
    const newSetPoint = parseInt(CurrentSetpoint) - 1;
    if (newSetPoint >= minTemperature) {
      setCurrentSetpoint(newSetPoint);
      handleDelayedSend(newSetPoint, 1);
    } else {
      console.warn("Temperatura mínima alcanzada");
    }
  };

  const upSet = () => {
    const newSetPoint = parseInt(CurrentSetpoint) + 1;
    if (newSetPoint <= maxTemperature) {
      setCurrentSetpoint(newSetPoint);
      handleDelayedSend(newSetPoint, 1);
    } else {
      console.warn("Temperatura máxima alcanzada");
    }
  };

  useEffect(() => {
    return () => clearTimeout(timer); // limpia el timer
  }, [timer]);

  useEffect(() => {
    const eventEmitter = getEventEmitter();
    const handleUpdateUnit = (unitId, message) => {
      if (item.variables.deviceId === unitId) {
        setCurrentTemperature(financial(message.ambientTemperature));
        setCurrentSetpoint(message.setpoint);

        if (message.operationStatus === "Off") {
          setOff(true);
          setCool(false);
          setHeat(false);
          setAuto(false);
        } else {
          setOff(false);
          setCool(message.operationMode === "CoolOn");
          setHeat(message.operationMode === "HeatOn");
          setAuto(message.operationMode === "AutoChangeOver");
        }
      }
    };

    eventEmitter.on("updateUnit", handleUpdateUnit);

    return () => {
      eventEmitter.off("updateUnit", handleUpdateUnit); // Limpia el listener cuando el componente se desmonte
    };
  }, [item.variables.deviceId]);

  const timeLoad = () => {
    setTimeout(() => {
      setLoad(false);
    }, delay);
  };

  const handleChangeThermostatState = (action) => {
    if (action === 1) {
      upSet();
    } else if (action === 2) {
      downSet();
    } else if (action === 3) {
      if (!cool) {
        setTimeout(() => {
          send(item, "CoolOn", 3);
          setLoad(true);
          setCool(!cool);
          setHeat(false);
          setAuto(false);
          setOff(false);
          timeLoad();
        }, delay);
      }
    } else if (action === 4) {
      if (!heat) {
        setTimeout(() => {
          setLoad(true);
          send(item, "HeatOn", 4);
          setHeat(!heat);
          setCool(false);
          setAuto(false);
          setOff(false);
          timeLoad();
        }, delay);
      }
    } else if (action === 5) {
      if (!auto) {
        setTimeout(() => {
          setLoad(true);
          send(item, "AutoChangeOver", 5);
          setHeat(false);
          setCool(false);
          setAuto(!auto);
          setOff(false);
          timeLoad();
        }, delay);
      }
    } else if (action === 6) {
      if (!off) {
        setTimeout(() => {
          setLoad(true);
          send(item, "Off", 6);
          setHeat(false);
          setCool(false);
          setAuto(false);
          setOff(!off);
          timeLoad();
        }, delay);
      }
    }
  };

  return (
    <>
      <div className={`_weather_container_new`}>
        <div className={`_weather_container_title_status_new `}>
          <div className="_weather__title_new">
            <span>{item?.alias}</span>
          </div>

          <div className="_weather__status_new">
            <span
              className={
                item?.variables?.CommFailure === "1"
                  ? `${"__icon_wifi_offline"}`
                  : `${"__icon_wifi_online"}`
              }
            >
              <i class="fas fa-wifi"></i>
            </span>
          </div>
        </div>

        <div
          className={`_weather_container_temperature_new ${
            load ? "__loading_weather_other_new" : false
          }`}
        >
          <div
            className={`${
              cool || heat || currentFanMode
                ? ""
                : "__weather_temperatur_off_new"
            } _weather_temperature_new`}
          >
            <span
              className={`${
                cool || heat || currentFanMode ? "" : "__temperatur_off_new"
              } __temperature_new`}
            >
              {CurrentSetpoint}
              <span
                style={{
                  display: "flex",
                  fontSize: "35px",
                  alignItems: "start",
                  marginTop: "10px",
                }}
              >
                °c
              </span>
            </span>
            <div className="_indoor_weather_new">
              <span className="__indoor_new">
                <span
                  style={{
                    display: "flex",
                    fontSize: "20px",
                    marginLeft: "20px",
                  }}
                >
                  {CurrentTemperature}°c
                </span>
              </span>
            </div>
          </div>

          <div className="__container_fan_new">
            <div className="fan_and_velocity_container">
              <div className="__color_new">
                <img
                  className={
                    currentFanMode === "HIGH"
                      ? "prueba"
                      : currentFanMode === "MEDIUM"
                      ? "prueba2"
                      : "prueba3"
                  }
                  src={fanCoolImage}
                  onClick={
                    permissionsRoleUser?.permissionsRole?.AreaDispMan
                      ? null
                      : null
                  }
                  alt="fan"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {currentFanMode === "HIGH" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "LOW" ||
                        currentFanMode === "MEDIUM" ||
                        currentFanMode === "HIGH"
                          ? "low"
                          : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "MEDIUM" || currentFanMode === "HIGH"
                          ? "medium"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "HIGH" ? "high" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : currentFanMode === "LOW" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "LOW" ? "low" : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "MEDIUM" || currentFanMode === "HIGH"
                          ? "medium"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "HIGH" ? "high" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : currentFanMode === "AUTO" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "AUTO" ? "low_now" : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "AUTO" ||
                        currentFanMode === "MEDIUM" ||
                        currentFanMode === "HIGH"
                          ? "medium_now"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        currentFanMode === "AUTO" ? "high_now" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : null}
              </div>
            </div>

            <div
              className={`_indoor_weather_new ${
                load ? "__loading_weather_other_new" : false
              }`}
            >
              <span className="__indoor_new_name">{currentFanMode}</span>
            </div>
          </div>
        </div>

        <div className="__loading_weather">
          {load ? <Loading_devices /> : false}
        </div>

        <div
          className={`_weather_container_mode_new ${
            load ? "__loading_weather_other_new" : false
          }`}
        >
          <div className="_weather_container_up_down_new">
            <div
              className="_weather_btn_up_down_new"
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(1)
                  : null
              }
            >
              <span className="__btn_up_new">
                <i class="fas fa-chevron-up"></i>
              </span>
            </div>

            <div
              className="_weather_btn_up_down_new"
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(2)
                  : null
              }
            >
              <span className="__btn_down_new">
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>
          </div>

          <div className="container_options_new">
            <div
              className={
                cool ? "_weather_mode_cool_bg_new" : "_weather_mode_cool_new"
              }
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(3)
                  : null
              }
            >
              COOL
            </div>

            <div
              className={
                heat ? "_weather_mode_heat_bg_new" : "_weather_mode_heat_new"
              }
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(4)
                  : null
              }
            >
              HEAT
            </div>

            <div
              className={
                auto ? "_weather_mode_auto_bg_new" : "_weather_mode_auto_new"
              }
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(5)
                  : null
              }
            >
              AUTO
            </div>

            <div
              className={
                off ? "_weather_mode_off_bg_new" : "_weather_mode_off_new"
              }
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? () => handleChangeThermostatState(6)
                  : null
              }
            >
              OFF
            </div>
          </div>
        </div>
      </div>
    </>

    // <div
    //   className={
    //     pluggin !== 0
    //       ? `_weather_container ${gridNumber}`
    //       : `_weather_container_pluggin ${gridNumber}_pluggin`
    //   }
    // >
    //   <div
    //     className={`_weather_container_title_status ${
    //       item.variables.CommFailure === "1" ? "_weather_offline" : null
    //     }`}
    //   >
    //     <div className="_weather__title">
    //       <span>{item.alias}</span>
    //     </div>
    //     <div
    //       className={`_weather__status ${
    //         item.variables.CommFailure === "1" ? "__icon_offline__" : null
    //       }`}
    //     >
    //       {item.variables.CommFailure === "1" ? (
    //         <span className="__icon_alert_sensor">
    //           <img src={alert} alt="alert" width="25" height="25" />
    //         </span>
    //       ) : (
    //         <span className="__icon_wifi">
    //           <i className="fas fa-wifi"></i>
    //         </span>
    //       )}
    //     </div>
    //   </div>
    //   {/* temperature */}
    //   <div
    //     className={`_weather_container_temperature ${
    //       load ? "__loading_weather_other" : false
    //     }`}
    //   >
    //     <div
    //       className={`${
    //         off ? "__weather_temperatur_off" : ""
    //       } _weather_temperature`}
    //     >
    //       <span className={`${off ? "__temperatur_off" : ""} __temperature`}>
    //         <span
    //           style={{
    //             display: "flex",
    //             alignItems: "flex-start",
    //             fontSize: "65px",
    //             fontFamily: "Open24",
    //           }}
    //         >
    //           {CurrentSetpoint}
    //         </span>

    //         <span
    //           style={{
    //             display: "flex",
    //             alignItems: "flex-start",
    //             marginTop: "10px",
    //             fontSize: "35px",
    //             fontFamily: "Open24",
    //           }}
    //         >
    //           °C
    //         </span>
    //       </span>
    //     </div>
    //     <div className="_weather_container_up_down">
    //       <div
    //         className="_weather_btn_up_down"
    //         onClick={
    //           permissionsRoleUser?.permissionsRole?.AreaDispMan
    //             ? () => {
    //                 handleChangeThermostatState(1);
    //               }
    //             : undefined
    //         }
    //       >
    //         <span className="__btn_up">
    //           <i className="fas fa-chevron-up"></i>
    //         </span>
    //       </div>
    //       <div
    //         className="_weather_btn_up_down"
    //         onClick={
    //           permissionsRoleUser?.permissionsRole?.AreaDispMan
    //             ? () => {
    //                 handleChangeThermostatState(2);
    //               }
    //             : undefined
    //         }
    //       >
    //         <span className="__btn_down">
    //           <i className="fas fa-chevron-down"></i>
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="__loading_weather">
    //     {load ? <Loading_devices /> : false}
    //   </div>
    //   <div
    //     className={`_indoor_weather ${
    //       load ? "__loading_weather_other" : false
    //     }`}
    //   >
    //     <span className="__indoor">{CurrentTemperature}°</span>
    //   </div>
    //   {/* status */}
    //   <div
    //     className={`_weather_container_mode ${
    //       load ? "__loading_weather_other" : false
    //     }`}
    //   >
    //     <div
    //       className={cool ? "_weather_mode_cool_bg" : "_weather_mode_cool"}
    //       onClick={
    //         permissionsRoleUser?.permissionsRole?.AreaDispMan
    //           ? () => {
    //               handleChangeThermostatState(3);
    //             }
    //           : undefined
    //       }
    //     >
    //       <span>COOL</span>
    //     </div>
    //     <div
    //       className={heat ? "_weather_mode_heat_bg" : "_weather_mode_heat"}
    //       onClick={
    //         permissionsRoleUser?.permissionsRole?.AreaDispMan
    //           ? () => {
    //               handleChangeThermostatState(4);
    //             }
    //           : undefined
    //       }
    //     >
    //       <span>HEAT</span>
    //     </div>
    //     <div
    //       className={auto ? "_weather_mode_auto_bg" : "_weather_mode_auto"}
    //       onClick={
    //         permissionsRoleUser?.permissionsRole?.AreaDispMan
    //           ? () => {
    //               handleChangeThermostatState(5);
    //             }
    //           : undefined
    //       }
    //     >
    //       <span>AUTO</span>
    //     </div>
    //     <div
    //       className={off ? "_weather_mode_off_bg" : "_weather_mode_off"}
    //       onClick={
    //         permissionsRoleUser?.permissionsRole?.AreaDispMan
    //           ? () => {
    //               handleChangeThermostatState(6);
    //             }
    //           : undefined
    //       }
    //     >
    //       <span>OFF</span>
    //     </div>
    //   </div>
    // </div>
  );
};
