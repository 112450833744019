import React, { useEffect, useState } from "react";

//Material UI components & styles
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Loading from "../../../../components/loading/Loading";

//GraphQl Hooks & Queries
import { useQuery } from "@apollo/client";
import { GET_BATTERY_NEW_DEVICES } from "../../../../controllers/reportController";

//Helpers
import { formatDateLocal } from "../../../../utils/helpers";
import {
  handleBatteryLevel,
  handleBatteryLevelColor,
} from "../utils/helperBattery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarDeviceFails: {
    position: "fixed",
    width: 600,
    zIndex: 10,
    height: 60,
    marginBottom: 100,
    boxShadow:
      "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
    backgroundColor: "#3f9948",
  },
  titleConainer: {
    marginLeft: theme.spacing(1),
    color: "white",
    paddingTop: 12,
    flex: 1,
  },
  title: {
    fontSize: 15,
  },
  spacingClose: {
    marginRight: 30,
    padding: "10px 15px",
    cursor: "pointer",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#069C08",
    },
  },
}));

const Battery_Newlevel = ({
  openBattery,
  handleCloseBattery,
  name,
  id_Battery,
}) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_BATTERY_NEW_DEVICES, {
    variables: { itemId: id_Battery },
  });

  const [dataBattery, setDataBattery] = useState();

  useEffect(() => {
    if (!loading && !error && data) {
      const batteryData = data.getAllNewDeviceByBatteryLevel?.data;
      if (batteryData !== undefined && batteryData.length !== 0) {
        setDataBattery(batteryData);
      }
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      open={openBattery}
      onClose={handleCloseBattery}
      TransitionComponent={Transition}
    >
      <div className={classes.appBarDeviceFails}>
        <Typography variant="h6" className={classes.titleConainer}>
          <span className={classes.spacingClose} onClick={handleCloseBattery}>
            X
          </span>
          <span
            title={`Reporte de bateria - ${localStorage.getItem(
              "nameProject"
            )} - ${name}`}
            className={classes.title}
          >
            Reporte de bateria -{" "}
            {`${localStorage.getItem("nameProject").substring(0, 25)}...`} -{" "}
            {name}
          </span>
        </Typography>
      </div>
      <div className="battery_devices">
        {!dataBattery || dataBattery.length === 0 ? (
          <div className="battery_device_empty">No hay datos</div>
        ) : (
          <>
            <div className="battery_device_title_container">
              <div className="battery_device_title_date">Fecha</div>
              <div className="battery_device_title_level">Nivel</div>
            </div>
            {[...dataBattery].reverse().map((i) => (
              <div className="battery_device_container" key={i.id}>
                <div className="battery_device_date">
                  {formatDateLocal(parseInt(i.x + "000"))}
                </div>
                <div className="battery_device_level">
                  <span className="battery_device_averange">{i.y}%</span>
                  <span
                    className={`battery_device_level_icon ${handleBatteryLevelColor(
                      i.y
                    )}`}
                  >
                    {handleBatteryLevel(i.y)}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Battery_Newlevel;
