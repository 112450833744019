import React, { useContext, useState, useEffect } from "react";
import LoginVrf from "./loginVrf/LoginVrf"
import VRFConfigurations from "./vrfConfigurations/VRFConfigurations";
import { AuthContext } from "../../../../../context/AuthProvider";

export const RenderContent = () => {
    const {
        tokenvrf
    } = useContext(AuthContext);


    if (tokenvrf) {
        return (<VRFConfigurations />)

    } else {
        return <LoginVrf />
    }
}


