import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import IconCortain from "./components/IconCortain";
import {
  WrapperCurtainSwitch,
  HeaderCurtainswitch,
  WrapperCurtainIcon,
  WrapperActions,
  WrapperActionsButtons,
  ActionsButton,
} from "./Curtain.Style";
import {
  handleChangeLevel,
  stopChange,
  handleSliderChange,
  sendWebSocketData,
  initialValue,
} from "./helperCurtain";
import { ExtractTypeGateway } from "../../../utils/helpers";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { styled } from "@mui/material/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#0a84ff" : "#007bff",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 5,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#d0d0d0",
  },
}));

const CurtainSwitch = (props) => {
  const { t } = useTranslation();

  const typeGateway = ExtractTypeGateway();
  const controllerStatus = props?.controllerStatus;
  const found = props?.curtains?.variables?.items.find(
    (element) => element.name === "dimmer"
  );
  const [idCourtain, setIdCourtain] = useState(props?.item?.id);
  const [level, setLevel] = useState(props?.item?.variables?.LoadLevelStatus);
  const [referenceLevel, setReferenceLevel] = useState(
    initialValue(typeGateway, props)
  );
  const [initialLevel, setInitialLevel] = useState(
    props?.item?.variables?.LoadLevelStatus
  );
  const [client, setClient] = useState(null);
  const intervalRef = useRef(null);

  function valuetext(value) {
    return `${value}%`;
  }
  initialValue(typeGateway, props);
  const dataSent = {
    id_device: props?.item?.id_in_controller,
    serial: props?.serial,
    action: "attenuate",
    value: level,
    variable: "ChangeStatus",
  };

  const dataStop = {
    id_device: props?.item?.id_in_controller,
    serial: props?.serial,
    action: "windowsStop",
    variable: "ChangeStatus",
  };

  const curtainStatus150 = {
    conFailure: props?.item?.variables?.CommFailure,
    visible: props?.item?.visible,
  };

  const curtainStatus700 = {
    onLine: props?.curtains?.variables?.onLine,
    visible: props?.curtains?.visible,
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (level !== initialLevel) {
        sendWebSocketData(dataSent, client);
      }
    }, 1500);

    return () => clearTimeout(timerId);
  }, [level]);
 

  useEffect(() => {
    if (
      props?.serial !== undefined &&
      props?.endPoint !== null &&
      props?.endPoint !== undefined
    ) {
      if (client) {
        client.onerror = client.onopen = client.onclose = null;
        client.close();
      }
      const newClient = new W3CWebSocket(`${props?.endPoint}`);
      newClient.onopen = () => {
        if (props?.serial)
          newClient.send(JSON.stringify({ channel: props?.serial }));
      };
      newClient.onerror = function () {
        console.log("Se ha producido un error en el cliente del socket");
      };
      newClient.onmessage = function (e) {
        if (typeof e.data === "string") {
          const response = JSON.parse(e.data);
          console.log("esto es response:",idCourtain, response);

          if (response?.action == "LoadLevelStatus" && response?.id_device == idCourtain   ) {
            setReferenceLevel(parseInt(response.status));
          }
        } else {
          // console.log("Mensaje recibido no es una cadena");
        }
      };
      newClient.onclose = () => {
        setClient(null);
      };
      setClient(newClient);
      //limpia la conexión al desmontar el componente
      return () => {
        if (newClient) newClient.close();
      };
    }
  }, [props?.serial]);

  return (
    <WrapperCurtainSwitch>
      <HeaderCurtainswitch
        onLine={curtainStatus700?.onLine}
        controllerStatus={controllerStatus}
        conFailure={curtainStatus150.conFailure}
      >
        {props?.item?.alias}
      </HeaderCurtainswitch>
      <WrapperCurtainIcon>
        <IconCortain level={referenceLevel} size="50" />
        {referenceLevel}%
      </WrapperCurtainIcon>
      <WrapperActions>
        <IOSSlider
          valueLabelDisplay="on"
          onChange={(event, newValue) =>
            handleSliderChange(
              newValue,
              setLevel,
              intervalRef,
              setReferenceLevel
            )
          }
          aria-label="Temperature"
          getAriaValueText={valuetext}
          min={0}
          max={100}
          value={referenceLevel}
        />
        <WrapperActionsButtons>
          <ActionsButton
            onClick={() =>
              handleChangeLevel(
                0,
                false,
                level,
                setReferenceLevel,
                intervalRef,
                setLevel
              )
            }
          >
            <i className="fas fa-chevron-down"></i>
            {t("VIEW_AREA.COMPONENT_CURTAIN_SWITCH.DOWN")}
          </ActionsButton>
          <ActionsButton
            onClick={() => sendWebSocketData(dataStop, client)}
            className="stopButton"
          >
            <i className="far fa-stop-circle"></i>
            {t("VIEW_AREA.COMPONENT_CURTAIN_SWITCH.STOP")}
          </ActionsButton>
          <ActionsButton
            onClick={() =>
              handleChangeLevel(
                100,
                true,
                level,
                setReferenceLevel,
                intervalRef,
                setLevel
              )
            }
          >
            <i className="fas fa-chevron-up"></i>
            {t("VIEW_AREA.COMPONENT_CURTAIN_SWITCH.UP")}
          </ActionsButton>
        </WrapperActionsButtons>
      </WrapperActions>
    </WrapperCurtainSwitch>
  );
};

CurtainSwitch.propTypes = {};

export default CurtainSwitch;
