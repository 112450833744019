import React, { useState, useEffect, useRef, useContext } from "react";
import { Devices } from "../../../../../constants/devices_types";
import Switch from "@material-ui/core/Switch";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import { AuthContext } from "../../../../../context/AuthProvider";

//multilanguage
import { useTranslation } from "react-i18next";

//Components
import LightSwitchModal from "../LightSwitchModal";
import MessageSuccess from "../../../../../components/message/MessageSuccess";
import MessageError from "../../../../../components/message/MessageError";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const Swicth = ({
  allSwitches,
  sendMessage,
  serial,
  dataSocket,
  controllerStatus,
}) => {
  const { t } = useTranslation();
  const { itemId, state, category } = dataSocket;
  const { alias, variables } = allSwitches;
  const { item_id, status, onLine } = variables;
  const [modalOpen, setModalOpen] = useState(false);
  const [switchStates, setSwitchStates] = useState(status);
  const [load, setLoad] = useState(false);
  const [previousState, setPreviousState] = useState("");
  const {
    setSuccessMessage,
    setErrorMessage,
    socketConection,
    permissionsRoleUser,
  } = useContext(AuthContext);

  useEffect(() => {
    if (
      dataSocket.category === "switch" &&
      dataSocket.itemId === item_id &&
      dataSocket.state !== switchStates
    ) {
      setSwitchStates(dataSocket.state);
    }
  }, [dataSocket, item_id, switchStates]);

  //Esto hace parte del modal de confirmación
  //  const handleOpenLightModal = () => {
  //    setModalOpen(!modalOpen);
  //  };

  // Esto hace parte del modal de confirmación
  const handleChangeClose = () => {
    setModalOpen(false);
  };

  const handleChangeSwitchState = () => {
    setSwitchStates(!switchStates);
    const newSwitch = !switchStates;
    setPreviousState();
    setTimeout(() => {
      // Esto hace parte del modal de confirmación
      // handleChangeClose();
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
        sendMessage({
          serial,
          channel: serial,
          id: item_id,
          eventType: "item_updated",
          value: String(newSwitch),
        });
        if (onLine && socketConection && controllerStatus) {
          setSuccessMessage(true);
        } else {
          setErrorMessage(true);
          setSwitchStates(status);
        }
      }, 1500);
    }, 1500);
  };

  if (allSwitches !== "undefined") {
    return (
      <>
        <div className="_generic_container">
          <div
            className={`_generics_container_title_status ${
              onLine && controllerStatus ? null : "__status_comfailure__"
            }`}
          >
            <div className="_generic__title">{alias}</div>
            <div className="_generic__status__wifi">
              <span
                className={
                  onLine && controllerStatus
                    ? `${"__icon_wifi_sensor"}`
                    : `${"__icon_wifi_sensor_offline"}`
                }
              >
                <i class="fas fa-wifi"></i>
              </span>
            </div>
          </div>
          <div className="_generic_container_clasification">
            <div
              className={`_generic_clasification ${
                load ? "__loading_clasification" : false
              }`}
            >
              <span>
                <img
                  src={
                    switchStates
                      ? Devices.BINARY_LIGHT_ON
                      : Devices.BINARY_LIGHT_OFF
                  }
                  alt="clasification"
                  width="60"
                  height="60"
                />
              </span>
            </div>
            <div className="__loading_devices">
              {load ? <Loading_devices /> : false}
            </div>
            <div
              className={`_generic_toggle row-decoration ${
                load ? "__loading_toogle" : false
              }`}
            >
              <div>
                <ThemeProvider theme={innerTheme}>
                  <Switch
                    size="medium"
                    checked={switchStates}
                    onChange={
                      permissionsRoleUser?.permissionsRole?.AreaDispMan
                        ? handleChangeSwitchState
                        : false
                    }
                  />
                </ThemeProvider>
                <br></br>
                {!switchStates ? (
                  <span>{t("VIEW_AREA.COMPONENT_SWITCH_CARD.OFF")}</span>
                ) : (
                  <span>{t("VIEW_AREA.COMPONENT_SWITCH_CARD.ON")}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <LightSwitchModal
          handleChangeSwitch={handleChangeSwitchState} 
          open={modalOpen}
          close={handleChangeClose}
        />  */}
        <MessageSuccess />
        <MessageError />
      </>
    );
  }
};
export default Swicth;
