import React, { useContext } from "react";

//Global context
import { AuthContext } from "../../../../context/AuthProvider";

//Material UI components
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

//Components
import AddingDeviceProject from "./AddingDeviceProject";
import AutoComplete from "../../../../components/tableInformation/components/AutoComplete";
import AddNewMetersModal from "./AddNewMetersModal";

const NoDeviceInProject = (props) => {
  const { data, dataProjects } = props;
  const { selectedProject } = useContext(AuthContext);
  const projectsForStepper = data?.getAllProjectsByUser?.Projects;

  return (
    <div className="warnings_container">
      <div className="no_selected_filter">
        <WarningAmberRoundedIcon sx={{ height: "88px", width: "98.29px" }} />
        <span>
          Usted no posee ningún proyecto con ese dispositivo. ¿Desea agregar
          uno?
        </span>
        <div className="add_device_filter_and_button">
          <div className="filter_project_to_add_device">
            <AutoComplete dataFilter={projectsForStepper} enabled={true} />
          </div>
          <AddingDeviceProject
            enabled={selectedProject === null ? true : false}
            openModal={false}
          />
        </div>
        <AddNewMetersModal data={dataProjects} />
      </div>
    </div>
  );
};

export default NoDeviceInProject;
